/* function closeModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');
    modalAll.forEach(item => {
        item.classList.remove('active');
        document.body.style.overflow = "";
        document.body.style.marginRight = '';
    });
}


const validation = new JustValidate('.bottom-form');

validation
    .addField('.input-name', [
        {
            rule: 'minLength',
            value: 3,
        },
        {
            rule: 'maxLength',
            value: 30,
        },
        {
            rule: 'required',
            value: true,
            errorMessage: 'Enter your name!'
        }
    ])
    .addField('.input-mail', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Email is required',
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Please enter a valid Email',
        },
    ])
    .addField('.input-tel', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Phone required',
        },
    ]).onSuccess((event) => {

        let formData = new FormData(event.target);
        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    console.log('Отправлено');
                }
            }
        };

        xhr.open('POST', 'mail.php', true);
        xhr.send(formData);

        event.target.reset();
    });


const validationTwo = new JustValidate('.application-form');

validationTwo
    .addField('.input-name-modal', [
        {
            rule: 'minLength',
            value: 3,
        },
        {
            rule: 'maxLength',
            value: 30,
        },
        {
            rule: 'required',
            value: true,
            errorMessage: 'Enter your name!'
        }
    ])
    .addField('.input-tel-modal', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Phone required',
        },
    ]).onSuccess((event) => {

        let formData = new FormData(event.target);
        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    closeModal();
                }
            }
        };

        xhr.open('POST', 'mail.php', true);
        xhr.send(formData);

        event.target.reset();
    });



const validationThree = new JustValidate('.application-form-hed');

validationThree
    .addField('.input-name-hed', [
        {
            rule: 'minLength',
            value: 3,
        },
        {
            rule: 'maxLength',
            value: 30,
        },
        {
            rule: 'required',
            value: true,
            errorMessage: 'Enter your name!'
        }
    ])
    .addField('.input-mail-hed', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Email is required',
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Please enter a valid Email',
        },
    ])
    .addField('.input-tel-hed', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Phone required',
        },
    ]).onSuccess((event) => {

        let formData = new FormData(event.target);
        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    closeModal();
                }
            }
        };

        xhr.open('POST', 'mail.php', true);
        xhr.send(formData);

        event.target.reset();
    });





 */