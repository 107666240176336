document.addEventListener('DOMContentLoaded', function () {


    function magnetic(trigger) {
        const btns = document.querySelectorAll(trigger);

        btns.forEach(btn => {
            btn.addEventListener('mousemove', function (e) {
                const position = btn.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const x = e.pageX - position.left - position.width / 2;
                const y = e.pageY - position.top - position.height / 2 - scrollTop;

                btn.children[0].style.transform = "translate(" + x * 0.1 + "px, " + y * 0.5 + "px)";
            });
        });

        btns.forEach(btn => {
            btn.addEventListener('mouseout', function (e) {
                btn.children[0].style.transform = "translate(0px, 0px)";
            });
        });
    }

    magnetic('.nav li');








});