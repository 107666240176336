document.addEventListener('DOMContentLoaded', function () {






    function rowAnimHorizontal(direction, elements) {
        const blocks = gsap.utils.toArray(elements);

        let currentWidth = 0;
        let prevWidth = 0;
        let widest;

        blocks.forEach((el, i) => {
            const width = gsap.getProperty(el, "width");
            if (width > prevWidth) {
                widest = width;
            }
            gsap.set(el, { x: currentWidth });
            currentWidth += width;
            prevWidth = width;
        });

        const totalWidth = currentWidth;

        const tl = gsap.to(blocks, {
            x: `${direction}=${totalWidth}`,
            repeat: -1,
            duration: 25,
            ease: "none",
            repeatRefresh: true,
            modifiers: {
                x: gsap.utils.unitize((x) =>
                    gsap.utils.wrap(-widest, totalWidth - widest, x)
                )
            }
        });
    }


    rowAnimHorizontal('-', ".anim-row div")
    rowAnimHorizontal('-', ".bottom-sec--first .anim-row div")

    if (window.innerWidth > 991) {

        $('.promo-card').hover(function () {

            let $this = $(this);
            let parent = $(this).parent();
            let innerForm = $('.right-block__inner')

            function removeClass() {
                parent.removeClass('first-active two-active three-active')
                innerForm.removeClass('active')
            }

            $(this).addClass('promo-card--active').siblings().removeClass('promo-card--active');

            if ($this.hasClass('promo-card--first')) {
                removeClass()
                parent.addClass('first-active')
                $('.first-form').addClass('active')
            }
            if ($this.hasClass('promo-card--two')) {
                removeClass()
                parent.addClass('two-active')
                $('.two-form').addClass('active')
            }
            if ($this.hasClass('promo-card--three')) {
                removeClass()
                parent.addClass('three-active')
                $('.three-form').addClass('active')
            }


        });

        function rowAnimVertical(direction, elements) {
            const blocks = gsap.utils.toArray(elements);

            let currentWidth = 0;
            let prevWidth = 0;
            let widest;

            blocks.forEach((el, i) => {
                const width = gsap.getProperty(el, "height");
                if (width > prevWidth) {
                    widest = width;
                }
                gsap.set(el, { y: currentWidth });
                currentWidth += width;
                prevWidth = width;
            });

            const totalWidth = currentWidth;

            const tl = gsap.to(blocks, {
                y: `${direction}=${totalWidth}`,
                repeat: -1,
                duration: 35,
                ease: "none",
                repeatRefresh: true,
                modifiers: {
                    y: gsap.utils.unitize((y) =>
                        gsap.utils.wrap(-widest, totalWidth - widest, y)
                    )
                }
            });
        }


        rowAnimVertical('-', ".right-block__inner.first-form div")
        rowAnimVertical('-', ".right-block__inner.two-form div")
        rowAnimVertical('-', ".right-block__inner.three-form div")




        const blockScroll = document.querySelector('.bottom-sec__wrap')
        let counter = 0;

        new fullpage('#fullpage', {
            autoScrolling: true,
            scrollHorizontally: true,
            scrollOverflow: true,
            scrollingSpeed: 1000,
            normalScrollElements: '.bottom-sec__wrap',
            menu: '.nav-sec',
            onLeave: function (origin, destination, direction, trigger) {
                if (origin.index == 0 && direction == 'down') {
                    $('.hide-elem').addClass('hide');
                }
                if (origin.index == 1 && direction == 'up') {
                    $('.hide-elem').removeClass('hide');
                }
                if (origin.index == 4 && direction == 'down') {
                    counter = 0;
                }
                if (origin.index == 4 && direction == 'up') {
                    counter = 0;
                }
            }
        });

        const arrows = document.querySelectorAll('.promo-card__link');

        arrows[0].addEventListener('click', function () {
            fullpage_api.moveTo(2, 0);
        });
        arrows[1].addEventListener('click', function () {
            fullpage_api.moveTo(3, 0);
        });
        arrows[2].addEventListener('click', function () {
            fullpage_api.moveTo(4, 0);
        });




        function onWheel(e) {
            e = e || window.event;
            counter += e.deltaY

            counter = Math.ceil(counter)

            if (counter >= 1800) {
                fullpage_api.moveSectionDown();
            }
            if (counter <= -1800) {
                fullpage_api.moveSectionUp();
            }
        }

        function onTouchMove(e) {
            e = e || window.event;
            counter += e.changedTouches[0].clientY

            counter = Math.ceil(counter)

            if (counter <= 650) {
                fullpage_api.moveSectionDown();
            }
            if (counter >= 650) {
                fullpage_api.moveSectionUp();
            }
        }


        /* if (blockScroll) {
            blockScroll.addEventListener("wheel", onWheel);

            blockScroll.addEventListener("touchend", onTouchMove);
        } */


    }


    if (window.innerWidth <= 991) {
        $('.promo-card').on('click', function () {
            $(this).addClass('promo-card--active').siblings().removeClass('promo-card--active');
        });
    }





});