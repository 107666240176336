function menu() {
    const burger = document.querySelector('.burger');
    const menu = document.querySelector('.nav-modal');
    const links = document.querySelectorAll('.nav-modal .nav ul li a');

    burger.addEventListener('click', function () {
        console.log('test');
        this.classList.toggle('active');
        menu.classList.toggle('active');
        document.body.classList.toggle('scroll-hide');
    });

    links.forEach(item => {
        item.addEventListener('click', function () {
            burger.classList.remove('active');
            menu.classList.remove('active');
            document.body.classList.remove('scroll-hide');
        });
    });




}

menu();